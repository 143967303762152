@charset "utf-8";
// @import "bulma";
@import "sass/utilities/functions";
@import "variables";
@import "sass/utilities/derived-variables";
@import "sass/utilities/initial-variables";
@import "sass/utilities/animations";
@import "sass/utilities/mixins";
@import "sass/utilities/controls";

@import "sass/base/_all";
@import "sass/elements/_all";
@import "sass/components/_all";
@import "sass/grid/_all";
@import "sass/layout/_all";


html {
    font-size: 62.5%;
}

body {
    font-family: $text-font, sans-serif;
    color: var(--text-color);
    font-size: 1.6rem;
}

h1, h1.title {
    font-weight: 200;
    font-size: 4.8rem;
    color: var(--primary-color);
}

h2 {
  font-size: 3.6rem;
  font-weight: 200;
}

h3 {
    font-size: 3.6rem;
    font-weight: 600;
}

h4 {
    font-size: 2.6rem;
}

.title {
    text-transform: uppercase;
}

p {
    font-size: 2.2rem;
    font-weight: 300;
    margin: 0 0 1.563rem 0;
    line-height: 3.6rem;
}

a {
    color: var(--primary-color)!important;
}

.button {
    font-size: $link-font, sans-serif;
    font-weight: 600;
}

.nav-header {
    background: var(--header-and-footer-background-color);
    color: var(--header-and-footer-color);

    a {
        color: var(--header-and-footer-color);
    }
}

.nav-no-bg {
    background: none;
    border-bottom: 1px solid #000;
}

.nav-logo {
    border:none;
}

.middled-box{
    display: flex;
    justify-content: center;
    align-items: center;
}

.section.gallery {
    padding: 3rem 0!important;

    .image img{
        max-width: 340px !important;
    }
}

.button {
    font-weight: 400;
    text-transform: uppercase;
    font-size: 1.8rem;
    padding-left: 3rem;
    padding-right: 3rem;
    transition: 0.4s all ease;
}

.button.is-primary-color {
    background: var(--primary-color)!important;
    color: $white!important;
}

.button:hover {
    background: var(--hover-color)!important;
    color: $white!important;
}

.is-small-button {
    padding: 16px 32px;
}

.is-big-button {
    padding: 32px 64px;
    font-weight: bold;

    +p {
        margin-top: 25px;
    }
}

.is-primary-color {
    background: var(--primary-color) !important;
    * {
        color: $text-color;
    }
    .button {
        border: 1px solid $text-color;
    }
    .button:hover {
        border: 1px solid $text-color;
    }
}

.button-white {
    background: $white !important;
    color: var(--primary-color) !important;
    border: 2px solid var(--primary-color) !important;
    border-radius: 4px;
    padding-left: 3rem;
    padding-right: 3rem;
}

.button-white:hover {
    background: var(--primary-color)!important;
    color:$white !important;
}

.nav {
    a {
        font-size: 1.8rem;
        color: var(--over-color);
    }
    height: 5.25rem;
}

.footer {
    background: var(--header-and-footer-background-color);
    a {
        color: var(--header-and-footer-color) !important;
    }
    * {
        color: var(--header-and-footer-color);
    }
    .nav {
        .nav-item {
            color: var(--header-and-footer-color)!important;
        }
        .is-active {
            font-weight: bold;
        }
    }

    .nav-no-bg {
        background: none;
        border-bottom: 1px solid var(--header-and-footer-color);
    }
}

.hero-image {
    h1 {
        font-size   : 4.8rem;
        color       : white;
        font-weight : bold;
    }
}

.copyright {
    margin-top: 20px;
}

.swiper-container {
    width: 600px;
    height: 300px;
}

.blue h3{
    background  : $text-color;
    color       : #3498db;
    font-size   : 36px;
    line-height : 100px;
    margin      : 10px;
    padding     : 2%;
    position    : relative;
    text-align  : center;
    border      : 1px solid;
}

.icon-link {
    line-height: 18px;
    a {
        color: $dark-text-color;
    }
}


.input-search {
    border        : 0;
    border-bottom : 1px var(--primary-color) solid;
    box-shadow    : none;
    border-radius : 0;
}

.pagination {
    font-size: 1.6rem;
}

.pagination-link {
    color: var(--primary-color);
}

.pagination-link.is-current {
    background : var(--primary-color);
    border-color     : var(--primary-color);
    color            : $text-color;
}

.jobs {
    .job {
        h4 {
            color: var(--primary-color)!important;
            font-weight: bold;
        }
        .job-title {
            border-top: 1px dashed #cccccc;
        }
    }
    padding:12px;
}

.job-details {
    &--padded {
        padding-left: 2.6rem;
    }

    font-size: 2.2rem;
    font-weight:300;
    h1 {
        margin-bottom: 10px;
    }

    div, p, span, ul, ol, li {
        font-size: 2.2rem!important;
        font-weight: 300;
    }

    ul, ol {
        padding-left: 4rem;
    }
}

.separator {
    border-top: 1px solid #cccccc;
}

.is-margin-auto {
    margin:auto;
}

.image-box {
    position: relative;
    width: 100%; /* for IE 6 */
    .person-info {
        margin-top: 25px;
        h3 {
            color: var(--primary-color);
        }
    }
    a, h2 {
        color: $white;
        font-family: Source Sans Pro, Roboto, sans-serif;
        font-weight: 600;
    }
    a:hover {
        cursor: pointer;
    }
    img {
        z-index: 9999;
    }

   .image-overlay {
        color: $text-color;
        left: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        i{
            width: 48px;
            height: 48px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: var(--primary-color)!important;
            background: var(--over-color)!important;
            border-radius: 50%;
        }
        h2{
            margin-top: 1.6rem;
        }
    }
    .big {
        top : 40%;
    }
}

.control.has-icons-left .icon, .control.has-icons-right .icon {
    height: 3.25rem;
}

.slick-slide .image {
    padding: 10px;
}
.slick-prev, .slick-next {
    width: 32px;
    height: 32px;
}

.slick-prev:before, .slick-next:before {
    font-family             : 'jobilla';
    font-size               : 32px;
    line-height             : 1;
    color                   : var(--primary-color);
    opacity                 : 0.75;
    -webkit-font-smoothing  : antialiased;
    -moz-osx-font-smoothing : grayscale;
}
.slick-prev:before {
    content: "i" !important;
}
.slick-next:before {
    content: "j" !important;
}


.slick-prev:before { content: "\f053"; }
[dir="rtl"] .slick-prev:before { content: "\f054"; }

[dir="rtl"] .slick-next { left: -10px; top: 70px; right: auto; }
.slick-next:before { content: "\f054"; }
[dir="rtl"] .slick-next:before { content: "\f053"; }


@media only screen and (max-width: 1024px) {
    h2 {
        font-size: 2.4rem;
    }
    h3 {
        font-size: 2rem;
    }
    p {
        font-size: 1.8rem;
    }
}

@media only screen and (max-width: 768px) {
    .section {
        margin-bottom: 1.5rem;
    }

    .social-icons {
        a {
            padding: 5px !important;

            i {
                width: 2.5rem !important;
                height: 3.5rem !important ;
                font-size: 2.5rem !important;
            }
        }
    }

    #articles {
        article {
            a {
                height: inherit;
                overflow: hidden;
                text-align: center;
                height: 160px;
                position: relative;

                > div {

                    img {
                        position: absolute;
                        top: -9999px;
                        bottom: -9999px;
                        left: -9999px;
                        right: -9999px;
                        margin: auto;


                        display: block;
                        height: 160px;
                        width: auto !important;
                        max-width: none !important;
                        z-index: 3 !important;
                    }
                }
            }
        }
    }
    .person-info {
        margin-top: 25px;
        padding: 1.4rem;

        h3 {
            color: var(--primary-color);
            font-size: 2.4rem;
            line-height: 2.6rem;
        }
        p{
            font-size: 1.6rem;
            line-height: 2.4rem;
        }
    }
    .image-box {
        position: relative;
        width: 100%; /* for IE 6 */

        a, h2 {
            color: $white;
            font-family: Source Sans Pro, Roboto, sans-serif;
            font-weight: 600;
        }


       .image-overlay {
            z-index: 300;

            i{
                width: 32px;
                height: 32px;
            }
            h2{
                margin-top: 1.2rem;
                font-size: 2.4rem;
                line-height: 2.6rem;
            }
        }
        .big {
            top : 40%;
        }
    }
}


@media only screen and (max-width: 480px) {
    html {
        font-size: 50%;
    }

    .is-small-button {
        padding: 5px 8px;
        font-size: 0.75rem;
    }
    .gallery {
        .image {
            max-width: 100vw!important;
        }
    }

    .job-details{
        padding-left: 0;
    }
}
